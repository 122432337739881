import React from "react"

const Testimonial = ({ data }: any) => {
  const { title, summary, client, reviewerDesignation, link, preview } = data

  // console.log("testimonial data", data)
  return (
    <div className="testimonial-single-box">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-5 col-sm-12 col-md-12">
          <div>
            <h3>{title}</h3>
          </div>
          <p className="summary">{summary}</p>
          <p className="link">
            <a href={link} rel="no-link" target="_blank">
              Read full review on clutch
            </a>
          </p>
          <div className="client-info pl-0">
            <h3>{client}</h3>
            <span>{reviewerDesignation}</span>
          </div>
        </div>
        <div className="col-lg-7 col-sm-12 col-md-12">
          <div className="clutch-screen">
            <a href={link} rel="no-link" target="_blank">
              <div className="hide-img">
                <img src={preview?.publicURL} alt={client} />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonial
