import React from "react"
import { Link, graphql } from "gatsby"
import * as Icon from "react-feather"
import { Helmet } from "react-helmet"
import Layout from "../../components/_App/layout"
import SEO from "../../components/_App/seo"
import NavbarNew from "../../components/NavbarNew"
import Footer from "../../components/_App/Footer"
import PageBanner from "../../components/Common/PageBanner"
import GetInTouch from "../../components/get-in-touch"
// import mobileAppIconBig from "../../assets/images/mobile-app-service-icon-big.png"
import mobileAppIconBig from "./mobile-app-service.png"

import ReactNative from "../../assets/images/react-native.svg"

import engagementBilling from "./engagement-billing.png"

import CaseStudyList from "../../components/case-study-list"
import Testimonial from "../../components/testimonial"
import RecipeList from "../../components/recipe-list"
import { MDXRenderer } from "gatsby-plugin-mdx"
import link from "../../assets/images/link.png"

import Loadable from "@loadable/component"
const OwlCarousel = Loadable(() => import("react-owl-carousel3"))
const options = {
  loop: true,
  nav: true,
  dots: true,
  autoplay: true,
  smartSpeed: 1000,
  autoplayTimeout: 7000,
  items: 1,
  navClass: ["owl-pre-icon", "owl-next-icon"],
  navText: ["", ""],
}
const awardsCarouselOptions = {
  loop: true,
  nav: true,
  dots: true,
  autoplay: true,
  smartSpeed: 1000,
  autoplayTimeout: 5000,
  items: 1,
  margin: 30,
  navClass: ["owl-pre-icon", "owl-next-icon"],
  navText: ["", ""],
}
const ServiceDetails = ({ data }) => {
  const [display, setDisplay] = React.useState(false)
  // console.log(data)

  const recipeFeatures = data?.ourRecipeFeatures?.nodes
  // console.log("recipeFeatures", recipeFeatures)

  const caseStudyList = data?.caseStudies?.nodes.filter(
    node => node.relativeDirectory.search("/") === -1
  )

  const featuredCaseStudy =
    data.featuredCaseStudy.siteMetadata.mobileFeaturedCaseStudies
  const filteredCaseStudyList = featuredCaseStudy.reduce((acc, cur) => {
    const studyListItem = caseStudyList.find(
      item => item.relativeDirectory === cur
    )
    if (studyListItem) {
      acc.push(studyListItem)
    }
    return acc
  }, [])
  console.log("filteredCaseStudyList", filteredCaseStudyList)
  // console.log("caseStudyList", caseStudyList)

  const awards = data?.awards?.nodes.filter(
    node => node.relativeDirectory.search("/") === -1
  )
  // console.log("awards", awards)
  const testimonials = data?.testimonials?.nodes.filter(
    node => node.relativeDirectory.search("/") === -1
  )
  // console.log("testimonials", testimonials)

  React.useEffect(() => {
    setDisplay(true)
  }, [])

  return (
    <Layout>
      <SEO
        title="Cross Platform Mobile Application Development Company"
        description="We are a top cross platform application development company having highly experienced app developer team for  e-commerce app development, Corporate app development."
        ogTwitterCardType="summary"
        url={`/services/cross-platform-app-development`}
        keywords={
          "cross platform app development, cross platform mobile development services, best cross platform app development company"
        }
      />

      <NavbarNew />

      <PageBanner pageTitle="Cross Platform Mobile Application Development" />

      <div className="services-details-area ptb-80">
        <div className="container">
          <div className="row align-items-center align-items-md-start">
            <div className="col-lg-6 col-md-6 services-details-image">
              <img
                src={mobileAppIconBig}
                className="animate__animated animate__fadeInUp animate__delay-0.6s"
                alt="Mobile App Development"
              />
            </div>
            <div className="col-lg-6 col-md-6 services-details">
              <div className="services-details-desc">
                {/* <h3>Incredible Infrastructure</h3> */}
                <p>
                  A cross platform app is best balance between value and cost,
                  while native app can provide the excellent performance it
                  would be high both on development and maintainance cost. Cross
                  platform apps gives the acceptable performance of most of the
                  business cases unless we are building very graphics heavy
                  apps. Cross platform let us keep most of the source code for
                  both Android and iOS builds common bringing significant
                  savings in develpoment cost.
                </p>

                <p>
                  We have a <a href="#our-recipe">recipe</a> to build a perfect
                  mobile app that helps you achieve your business goals in given
                  business parameters. We design and deliver an initiative and
                  personalized experience to the user through the app.
                </p>

                <p>
                  We measure the success of the app by the retention rate and we
                  had delivered winning apps to many businesses to date. Explore
                  our mobile app development case studies{" "}
                  <a href="#case-studies">here</a>. It fills us with content to
                  know that our clients love our work; discover their
                  testimonials <a href="#testimonials">here</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="our-recipe pt-80 pb-50 bg-f9f6f6" id="our-recipe">
        <div className="container">
          <div className="section-title">
            <h2 className="hover-link">
              <span>
                <a href="#our-recipe">
                  <img src={link} alt="" />
                </a>
                Our Recipe
              </span>
            </h2>
            <div className="bar"></div>
            <p>
              Our recipe to deliver winning apps is the perfect blend of
              processes, practices, and efficient tools combined with people of
              the right skill set. We groom our team with a favorable culture
              that encourages them to understand fundamentals and appreciate the
              value they provide to businesses. Below mentioned are the guiding
              principles of our recipe. Our full recipe is available{" "}
              <a href="/our-recipe">here</a>
            </p>
          </div>
          {recipeFeatures &&
            recipeFeatures.length > 0 &&
            recipeFeatures.map((list, index) => {
              const type = index % 2 === 0 ? "odd" : "even"
              // console.log("type", type)
              return (
                <RecipeList
                  title={list.childMdx.frontmatter.title}
                  content={<MDXRenderer>{list?.childMdx?.body}</MDXRenderer>}
                  thumb={list.childMdx.frontmatter.thumbImg.publicURL}
                  type={type}
                />
              )
            })}
        </div>
        <div className="text-align-center">
          <a className="btn btn-primary" href="/our-recipe">
            Learn More
          </a>
        </div>
      </div>

      <div className="iot-why-choose-us pt-80" id="tech-stack">
        <div className="container">
          <div className="section-title">
            <h2 className="hover-link">
              <span>
                <a href="#tech-stack">
                  <img src={link} alt="" />
                </a>
                Tech Stack
              </span>
            </h2>
            <div className="bar"></div>
            <p>
              We build apps using cross platform technologies which gives best
              balance between performance and cost.
            </p>
          </div>

          <div className="row justify-content-center tech-stack-gap">
            <div className="col-lg-7 col-md-9">
              <div className="single-iot-box">
                <div className="icon">
                  <a
                    href="https://reactnative.dev/"
                    target="_blank"
                    rel="nofollow"
                    className="tech-logo"
                  >
                    <img src={ReactNative} alt="image" />
                  </a>
                </div>
                <h3>React Native</h3>
                <p>
                  We build apps on a robust and cross-platform React Native tech
                  stack using one of the Redux or Mobx for the state management.
                  React Native helps us strike the best balance between
                  performance and cost. It gives near-native performance while
                  saving development and maintenance costs by keeping most of
                  the app's code common across the mobile platforms- Android and
                  iOS
                </p>
                <Link
                  to="https://reactnative.dev/"
                  target="_blank"
                  rel="nofollow"
                >
                  <Icon.ArrowRight />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="case-studies ptb-80" id="case-studies">
        <div className="container">
          <div className="section-title">
            <h2 className="hover-link">
              <span>
                <a href="#case-studies">
                  <img src={link} alt="" />
                </a>
                Case Studies
              </span>
            </h2>
            <div className="bar"></div>
            <p>
              We have delivered the success to businesses who are market leaders
              of their industries through our time tested methodologies and
              values. These case studies demonstrate the effectivity{" "}
              <a href="#our-recipe">our recipe</a> and capabilities.
            </p>
          </div>
          <div className="row justify-content-center">
            {filteredCaseStudyList && filteredCaseStudyList.length > 0 && (
              <div className="row justify-content-center">
                {filteredCaseStudyList.map((list, index) => (
                  <div className="col-lg-4 col-sm-6 col-md-6" key={index + 1}>
                    <CaseStudyList data={list?.childMdx} />
                  </div>
                ))}
              </div>
            )}
            <div className="text-align-center">
              <a className="btn btn-primary" href="/our-work">
                View More
              </a>
            </div>
            {/* <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="single-solutions-box case-study-tile">
                <div className="picture">
                  <img
                    src={haavooCaseStudy}
                    alt="Haavoo Case Study"
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <Link to="/">
                    Haavoo - Business directory platform that scaled reliably to
                    5k businesses
                  </Link>
                </h3>
                <p>
                  We delivered the stable MVP of the platform that includes
                  Admin panels, Web Portal and Android and iOS apps, which is
                  being used 5K businesses to date. We have kept the cost of
                  building MVP to a minimum that helped clients to test the
                  business idea at minimum risk.
                </p>

                <Link to="/" className="learn-more-btn">
                  <Icon.PlusCircle /> Know More
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="single-solutions-box case-study-tile">
                <div className="picture">
                  <img
                    src={rufoCaseStudy}
                    alt="Haavoo Case Study"
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <Link to="/">
                    Rufo - Business directory platform that scaled reliably to
                    5k businesses
                  </Link>
                </h3>
                <p>
                  We delivered the stable MVP of the platform that includes
                  Admin panels, Web Portal and Android and iOS apps, which is
                  being used 5K businesses to date. We have kept the cost of
                  building MVP to a minimum that helped clients to test the
                  business idea at minimum risk.
                </p>

                <Link to="/" className="learn-more-btn">
                  <Icon.PlusCircle /> Know More
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="single-solutions-box case-study-tile">
                <div className="picture">
                  <img
                    src={excessCaseStudy}
                    alt="Haavoo Case Study"
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <Link to="/">
                    Excess2Sell - Business directory platform that scaled
                  </Link>
                </h3>
                <p>
                  We delivered the stable MVP of the platform that includes
                  Admin panels, Web Portal and Android and iOS apps, which is
                  being used 5K businesses to date. We have kept the cost of
                  building MVP to a minimum that helped clients to test the
                  business idea at minimum risk.
                </p>

                <Link to="/" className="learn-more-btn">
                  <Icon.PlusCircle /> Know More
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="bigdata-feedback-area pt-80 pb-50" id="testimonials">
        <div className="container">
          <div className="section-title">
            <h2 className="hover-link">
              <span>
                <a href="#testimonials">
                  <img src={link} alt="" />
                </a>
                Testimonials
              </span>
            </h2>
            <div className="bar"></div>
            <p>
              There is a reason why we are recognized as top developers, we
              deliver...
            </p>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12">
              {display ? (
                <OwlCarousel className="owl-carousel owl-theme" {...options}>
                  {testimonials &&
                    testimonials.length > 0 &&
                    testimonials.map(list => (
                      <Testimonial data={list?.childMdx?.frontmatter} />
                    ))}
                </OwlCarousel>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="awards-recognition pt-80 pb-50" id="awards-recognition">
        <div className="container">
          <div className="section-title">
            <h2 className="hover-link">
              <span>
                <a href="#awards-recognition">
                  <img src={link} alt="" />
                </a>
                Awards and Recognition
              </span>
            </h2>
            <div className="bar"></div>
            <p>We are proud to be recognized for quality of our work</p>
          </div>
          <div className="awards-screenshots pb-80">
            <OwlCarousel
              className="owl-carousel owl-theme"
              {...awardsCarouselOptions}
            >
              {awards &&
                awards.length > 0 &&
                awards.map((list, index) => (
                  <div className="awards-wrapper" key={index + 1}>
                    <h3>{list?.childMdx?.frontmatter.title}</h3>
                    <div
                      className={`img-wrapper ${
                        list?.childMdx?.frontmatter?.imageSize === "big"
                          ? "big"
                          : ""
                      }`}
                    >
                      <img
                        src={list?.childMdx?.frontmatter.preview?.publicURL}
                        alt={list?.childMdx?.frontmatter.name}
                      />
                    </div>
                  </div>
                ))}
            </OwlCarousel>

            {/* <div className="clutch-badge">
              <a
                href="https://clutch.co/profile/tarkashilpa-technologie"
                target={"_blank"}
              >
                <img
                  src={mobileAppDeveloper}
                  className="animate__animated animate__fadeInDown animate__delay-0.2s"
                  alt="Mobile App Developer"
                />
              </a>
            </div> */}
            {/* <div className="manifest">
              <a
                href="https://themanifest.com/in/app-development/react-native/companies/mumbai#tarkashilpatechnologies"
                target={"_blank"}
              >
                <img
                  src={manifest}
                  className="animate__animated animate__fadeInDown animate__delay-0.2s"
                  alt="manifest"
                />
              </a>
            </div> */}
          </div>
        </div>
      </div>

      <div
        className="services-area ptb-80 bg-f7fafd"
        id="engagement-and-billing-model"
      >
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 services-content">
              <div className="section-title">
                <h2 className="hover-link">
                  <span>
                    <a href="#engagement-and-billing-model">
                      <img src={link} alt="" />
                    </a>
                    Pricing
                  </span>
                </h2>
                <div className="bar"></div>
              </div>
              <p>
                We believe in setting the expectations upfront and keep the
                pricing transparent. We also believe in educating our clients if
                necessary with the pros and cons of different engagement,
                delivery and billing models so they can take a informed
                decision. The choice of right models depends on various factors
                and we love to work with you to help you choose the right one
                based on your needs.
              </p>
              <p>
                Best pricing plan for you depends on if your primarily objective
                is in-budget delivery or high delivering apps. We recommend you
                to keep in-budget delivery as the objective if you are just
                starting the app or have limited time or knowledge to get into
                the nitty gritty of the deliveries. We would make sure we
                deliver in budget while keeping app's ROI to optimum.
              </p>
              <p>
                If you are looking for apps that deliver huge business outcomes
                then we recommend agile delivery model where we build the app by
                trial and iterations. There is reason why trail and iterations
                delivers high performing app, it's impossible to define and
                design the perfect app on paper, best way it to build a good
                enough app and validate, take feedback and improvise in further
                iteration. We cover you from budget standpoint by providing
                ballpark budget and are committed to get the delivery done well
                within this budget.
              </p>
            </div>

            <div className="col-lg-6 col-md-12">
              <img
                src={engagementBilling}
                className="animate__animated animate__zoomIn animate__delay-0.2s"
                alt="engagementBilling"
              />
            </div>
          </div>
        </div>
      </div>

      <GetInTouch />

      <Footer />
    </Layout>
  )
}

export default ServiceDetails

export const query = graphql`
  query crossPlatformAppServices {
    caseStudies: allFile(
      filter: {
        sourceInstanceName: { eq: "caseStudies" }
        name: { eq: "index" }
      }
    ) {
      nodes {
        childMdx {
          frontmatter {
            title
            thumbImg {
              publicURL
            }
            thumbImgAlt
            summary
          }
          slug
        }
        sourceInstanceName
        relativeDirectory
        name
      }
    }
    testimonials: allFile(
      filter: {
        sourceInstanceName: { eq: "testimonials" }
        name: { eq: "index" }
        childMdx: {
          slug: {
            in: ["deepak-puthiyedath/", "roshan-radhakrishnan/", "amit-kundra/"]
          }
        }
      }
    ) {
      nodes {
        childMdx {
          frontmatter {
            client
            reviewerDesignation
            title
            summary
            preview {
              publicURL
            }
            link
          }
        }
        relativeDirectory
        name
      }
    }
    awards: allFile(
      filter: {
        sourceInstanceName: { eq: "awards" }
        name: { eq: "index" }
        childMdx: { slug: { in: ["top-mobile-app/", "the-manifest/"] } }
      }
      sort: { fields: childrenMdx___frontmatter___order }
    ) {
      nodes {
        childMdx {
          frontmatter {
            name
            title
            order
            preview {
              publicURL
            }
            imageSize
          }
          slug
        }
        relativeDirectory
        name
      }
    }
    ourRecipeFeatures: allFile(
      filter: {
        sourceInstanceName: { eq: "ourRecipeFeatures" }
        name: { eq: "index" }
      }
      sort: { order: ASC, fields: childrenMdx___frontmatter___order }
    ) {
      nodes {
        childMdx {
          frontmatter {
            key
            title
            thumbImg {
              publicURL
            }
            thumbImgAlt
            order
          }
          body
        }
      }
    }
    featuredCaseStudy: site {
      siteMetadata {
        mobileFeaturedCaseStudies
      }
    }
  }
`
